@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  list-style: none;
}

:root{
  --blue:#1273C4;
  --gray-light:#F9F9FA;
  /* --gray-light: #9a9a9a; */
  --gray-dark:#515151;
  --gray:#EBEBEB;
  --red:#E35B48;
  --green:#5EAC24;
  --blue-light:rgb(48, 146, 191);
}

html{
  width: 100vw;
  overflow-x: hidden;
  background-color: var(--gray-light);
  /* background-color: #aaaaab; */
  color: var(--gray-dark);

}

.poppin-700 {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
}

.poppin-600 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}

.poppin-500 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

.dashboardTableWrapper {
  overflow-x: auto !important;
}

.dashboardTableWrapper table thead th{
  background: var(--blue-light);
}

#searchinput{
  box-shadow:0 0 10px 1px #e6e6e6;
}


.modalBg{
  background-color: rgba(0, 0, 0, 0.367);
}
.passwordIcon {
  top: 70%;
  right: 0px;
  transform: translateY(-50%)
}

.passwordIconRecenter{
  top: 55%;
  right: 0px;
  transform: translateY(-50%)
}

.unactive{
  transform: translateX(-100%);
}
.active{
    transform: translateX(0%);
}

.active > .sidebar{
  transition: all .3s ease-in-out;
  transform: translateX(0%);
}
.unactive > .sidebar {
  transition: all .4s ease-in-out;
  transform: translateX(-100%);
}
